<div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
  <div class="basis-1/2 flex flex-row items-center ml-3 font-medium noWrap h-12">
    {{ _date | date:'EEE, dd.MM.yyyy' }}
  </div>
  <div class="basis-1/2 flex flex-row justify-end items-center mr-3">
    <nx-default-button
      class="ml-2"
      (click)="createTour()"
      [disabled]="_location === undefined"
      *ngIf="rolesService?.hasRole('nexcore_tour_create')"
      [tooltip]="'neue Tour anlegen'"
      [label]="'Tour anlegen'"
      [style]="'light'"
      [icon]="'icon-plus'">
    </nx-default-button>

    <nx-default-button
      class="ml-2"
      *ngIf="!isTourComponentExpanded"
      (click)="expandTourComponent.emit()"
      [tooltip]="'Modul erweitern'"
      [style]="'light'"
      [icon]="'icon-after'">
    </nx-default-button>

    <nx-default-button
      class="ml-2"
      *ngIf="isTourComponentExpanded"
      (click)="reduceTourComponent.emit()"
      [tooltip]="'Modul reduzieren'"
      [style]="'light'"
      [icon]="'icon-back-to'">
    </nx-default-button>
  </div>
</div>

<!-- filter box -->
<div
 class="flex flex-row pt-[0.4rem] pb-1 pl-3 pr-3 bg-white"
  [ngClass]="{ 'disabled': _location === undefined}">
  <div class="basis-2/3 flex flex-row gap-2">
    <!-- 
    
    (onChange)="filter($event.value)" -->
    <nx-filter-multiSelect
      [(ngModel)] = "statesModel"
      [options]="statesOptions"
      [disabled]="_location === undefined"
      [className]="'table-filter'"
      [filter]="false"
      [showToggleAll]="false"
      [showHeader]="false"
      [appendTo]="'body'"
      [showClear]="true"
      (onChange)="onStateModelChange()"
      (onClear)="onStateModelChange()"
      placeholder="Status"
      optionLabel="label">
      <ng-template let-option pTemplate="item">
        <div class="inline-block vertical-align-middle">
          <span class="ml-1 mt-1">{{ option.name }}</span>
        </div>
      </ng-template>
  </nx-filter-multiSelect>
  </div>
  <div class="basis-1/3 flex flex-row justify-end">
    <!-- 
    (onChange)="dsSort($event)" -->
    <p-dropdown
      [disabled]="_location === undefined"
      [(ngModel)] = "sortModel"
      [options]="sortOptions"
      (onChange)="onSortModelChange()"
      placeholder="Sortierung"
      [styleClass]="'sort'"
      appendTo="body"
      optionLabel="label"
      [showClear]="false">
    </p-dropdown>
  </div>
</div>
<!--  -->
<div
  #xScrollContainer 
  class="tourGroups flex overflow-scroll h-[calc(100%-80px)] no-scrollbar"
  [ngClass]="isTourComponentExpanded ? ['flex-row', 'gap-4'] : ['flex-col']">

  <div
    *ngIf="_location !== undefined && dailyToursSortedNotPlanned?.length > 0"
    class="tourGroup unplanned min-w-[500px] max-w-[570px]">
    <h2 class="text-base font-medium p-2">{{notPlannedHeadline}}</h2>
    <div tours-planner-tours-list
      class="toursList"
      [sortBy]="sortModel?.id"
      [tours]="dailyToursSortedNotPlanned"
      (refetch)="refetchQuery()">
    </div>
  </div>

  <ng-container *ngIf="_location !== undefined && dailyToursSorted?.length > 0">

    <div class="tourGroup planned min-w-[500px] max-w-[570px]"
    *ngFor="let daily of dailyToursSorted; let i = index">
        <h2 class="text-base font-medium p-2">{{getPlannedHeadline(daily)}}</h2>
        <div tours-planner-tours-list
          class="toursList"
          [sortBy]="sortModel?.id"
          [tours]="daily.tours"
          (refetch)="refetchQuery()">
        </div>
    </div>

  </ng-container>

  <nx-empty-box
    class="mt-2 w-full"
    *ngIf="_location !== undefined && !(dailyToursSortedNotPlanned?.length > 0) && !(dailyToursSorted?.length > 0)"
    [label]="'Keine Touren gefunden.'">
  </nx-empty-box>

  <nx-empty-box
    class="mt-2 w-full"
    *ngIf="_location === undefined"
    [label]="'Keine Niederlassung ausgewählt.'">
  </nx-empty-box>

</div>

<!-- {{notPlannedHeadline}}
{{dailyToursSortedNotPlanned | json}}

PLANNED:
{{dailyToursSorted | json}} -->


<!-- empy box if no location is selected -->


