<nx-headline-box
  label="Kommentare">
</nx-headline-box>

<nx-empty-box *ngIf="!comments|| comments?.length<1"
  [ngClass]="{
    'min-w-[485px]': style === 'overlay' }"
  [label]="'Keine Kommentare vorhanden.'">
</nx-empty-box>

<div
*ngFor="let comment of comments; let isFirst = first">
<nx-default-box class="bg-white border-b border-gray"
  [ngClass]="{
    'min-w-[485px]': style === 'overlay' }"
  [label]="getName(comment?.lastModifiedBy)"
  [menuItems]="getItems(comment)"
  [subLabel]="(comment?.lastModifiedDateTime | date: 'dd.MM.yyyy HH:mm') + ' Uhr'">
   <ng-template nxTemplate="body">
    <ng-container *ngIf="editComment !== comment">
      {{comment?.text}}
     </ng-container>
    <ng-container *ngIf="editComment === comment">
      <!-- edit comment -->
      <div class="addComment bg-white py-2 mt-2">
        <div [formGroup]="editFormGroup">
          <textarea
            class="w-full"
            rows="5"
            placeholder="Kommentar bearbeiten..."
            pInputTextarea
            [autoResize]="true"
          formControlName="text"></textarea>
        </div>
      </div>
      <div class="flex flex-row pb-2 gap-x-2 bg-white">
        <nx-default-button
          [disabled]="!(editFormGroup.valid && !editFormGroup.pristine)"
          [label]="'Speichern'"
          (click)="!(editFormGroup.valid && !editFormGroup.pristine) ? $event.stopPropagation() : saveComment()">
        </nx-default-button>
        <nx-default-button
          [disabled]="editFormGroup.pristine"
          [label]="'Abbrechen'" (click)="editFormGroup.pristine ? $event.stopPropagation() : cancelEditComment()">
        </nx-default-button>
      </div>
    </ng-container>
  </ng-template>
</nx-default-box>
</div>

<!-- add comment -->
<div class="addComment bg-white px-5 py-2 mt-2 form">
  <div [formGroup]="createFormGroup">
    <textarea
      class="w-full"
      rows="5"
      placeholder="Kommentar eingeben..."
      pInputTextarea
      [autoResize]="true"
      formControlName="text"></textarea>
    </div>
  </div>
    <div class="flex flex-row px-5 pb-2 gap-x-2 bg-white justify-end ">
      <nx-default-button
      [style]="'dark'"
      [disabled]="createFormGroup?.pristine"
      [label]="'Abbrechen'" (click)="createFormGroup?.disabled ? $event.stopPropagation() : cancelSaveComment()">
      </nx-default-button>
      <nx-default-button
        [style]="'dark'"
        [disabled]="!(createFormGroup?.valid && !createFormGroup?.pristine)"
        [label]="'Speichern'"
        (click)="createFormGroup?.disabled ? $event.stopPropagation() : saveComment()">
      </nx-default-button>
</div>
