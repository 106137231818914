<nx-pageTitle-box
  [bcRootLvlLabel]="'Tourenplaner'"
  [bcRootLvlPath]="'/tour-planner'"
  class="mb-3"
  [label]="this.tour?.name"
  [icon]="'icon-tour'"
  [iconToolTip]="'Tour'"
  [menuItems]="pageTitleMenuItems">
  <ng-template nxTemplate="tag">
    <p-tag class="mr-2" [value]="tour?.getStateLabel()" [severity]="tour?.getStateSeverity()"></p-tag>
  </ng-template>
</nx-pageTitle-box>
<div class="flex flex-row gap-4 h-[calc(100%-80px)]">
  <div #xScrollContainer class="flex-shrink-0 min-w-[500px] max-w-[570px] overflow-auto">
    <nx-headline-box 
      [label]="'Tourdaten'">
    </nx-headline-box>
    <div class="flex flex-row">
        <nx-default-box
        class="basis-1/2 bg-white border-b border-gray"
        [label]="'Fahrer'">
        {{ tour?.driver?.firstName }} {{ tour?.driver?.lastName }}
      </nx-default-box>
      <nx-default-box
        class="basis-1/2  bg-white border-b border-gray"
        [label]="'Fahrzeug'">
        {{ tour?.resource?.name }}
      </nx-default-box>
    </div>
    <div class="flex flex-row">
      <nx-default-box
        class="basis-1/2 bg-white border-b border-gray"
        [label]="'Niederlassung'">
        {{ tour?.location?.name }}
      </nx-default-box>
      <nx-default-box
        class="basis-1/2 bg-white border-b border-gray"
        [label]="'Planungsdatum'">
        {{ tour?.dateTimeReference | date:'dd.MM.yyyy' }}
      </nx-default-box>
    </div>
    <nx-default-box
      class="basis-1/2  bg-white border-b border-gray"
      [label]="'Tour-Hinweis'">
      {{ tour?.note }}
    </nx-default-box>
    <div class="flex flex-row">
      <nx-default-box
        class="basis-1/2 bg-white border-b border-gray"
        [label]="'Distanz'">
        {{_sumDistance? (_sumDistance | number:'1.0-1') + ' km' : undefined }}
      </nx-default-box>
      <nx-default-box
        class="basis-1/2 bg-white border-b border-gray"
        [label]="'Fahrzeit'">
        {{ _sumDuration?  (_sumDuration / 60 | number:'1.2-2') + ' h' : undefined}}
      </nx-default-box>
    </div>

    <nx-headline-box 
      class="mt-3"
      [label]="'Route'">
    </nx-headline-box>

    <div class="flex flex-row bg-white border-b border-gray">
      <div class="w-[26px] flex items-center place-content-center">
        <div class="text-base pl-1">A</div>
      </div>
      <span class="flex basis-1/3 items-center row pl-3 text-base">
        {{tour?.effectiveDepartureDateTime ? (tour?.effectiveDepartureDateTime | date:'HH:mm, dd.MM.')  : '--:--'}}
      </span>
      <div
        class="flex basis-2/3 items-center place-content-end pr-3 text-base">
       {{tour?.departureAddress?.getAddressString()? tour?.departureAddress?.getAddressString() : '–'}}
        </div>
    </div>

    <div
      *ngIf="tour?.tasks?.length > 0" 
      class="text-sm bg-white border-b border-gray">
      {{_route?.sections[0]?.summary?.length ? (_route?.sections[0]?.summary?.length / 1000 |
              number:'1.0-1') : '-'}} km / {{_route?.sections[0]?.summary?.baseDuration ? (_route?.sections[0]?.summary?.baseDuration / 60 | number:'1.0-0') : '-'}} min
    </div>

    <nx-empty-box *ngIf="!tour?.tasks || (tour?.tasks?.length === 0)"
      class="empty-box"
      [labelOverwriteClass]="'text-base font-light text-[#848488] px-5 py-2 bg-white border-b flex items-center justify-center h-[59px] min-w-[280px]'"
      [label]="'Keine Aufträge geplant.'"
      ></nx-empty-box>

    <div 
      nxDropList
      [nxDropListData]="tour"
      [nxIsDropDisabled]="true"
      [id]="'toursTaskList'"
      class="flex flex-col tasks">

        <div
          nxDragItem
          [nxDragItemIsDropTarget]="true"
          [nxIsDropDisabled]="isDropDisabled(tour)"
          [nxDragItemData]="task"
          [nxDragItemDropListIndex]="i"
          [nxDragItemDropListId]="tour?.id"
          [nxIsDragDisabled]="isDragDisabled(task)"
          (nxDragDropped)="dragDropped($event)"
          (nxDragStarted)="dragStarted($event)"
          (nxDragEnded)="dragReleased($event)"         
          *ngFor="let task of tour?.tasks; 
          let isFirst = first; 
          let isLast = last; 
          let i = index; 
          let c = count"
          class="flex flex-col task">
          
          <div class="taskRow flex task border-b">
            <div class="w-[26px] flex items-center place-content-center">
              <div class="letter">{{toLetters(i + 2 )}}</div>
            </div>

            <div
              task-row
              (refetchTasks)="refetch()"
              [task]=task
              class="flex flex-row basis-full items-center">
            </div>

          </div>

          <div class="text-sm bg-white border-b border-gray">
            {{_route?.sections[i+1]?.summary?.length ? (_route?.sections[i+1]?.summary?.length / 1000 |
                number:'1.0-1') : '-'}} km / {{_route?.sections[i+1]?.summary?.baseDuration ? (_route?.sections[i+1]?.summary?.baseDuration / 60 | number:'1.0-0') : '-'}} min
          </div>
        </div>

    </div>

    <div class="flex flex-row bg-white border-b border-gray">
      <div class="w-[26px] flex items-center place-content-center">
        <div class="text-base pl-1">{{toLetters(tour?.tasks?.length + 2 )}}</div>
      </div>
      <span class="flex basis-1/3 items-center row pl-3 text-base">
        {{tour?.effectiveArrivalDateTime ? (tour?.effectiveArrivalDateTime | date:'HH:mm, dd.MM. ') : '--:--'}}
      </span>
      <div
        class="flex basis-2/3 items-center place-content-end pr-3 text-base">
       {{tour?.arrivalAddress?.getAddressString()? tour?.arrivalAddress?.getAddressString() : '–'}}
        </div>
    </div>

  </div>
  <div class="flex-grow overflow-auto">
    <nx-headline-box
      [label]="'Karte'">
    </nx-headline-box>
    <div class="flex basis-full map h-[calc(100%-48px)] bg-white p-4">
      <app-tours-detail-planning-map class="flex basis-full map h-full" [tour]="tour"
        (routeCalculated)=routeCalculated($event)>
      </app-tours-detail-planning-map>
    </div>
  </div>
</div>
<!-- <div class="content">
  <div class="header flex flex-row" [ngClass]="tour?.state">
    <div class="headline flex flex-row  items-center basis-full">
      <ng-container ngProjectAs="[headline]">
        <i [className]="tour?.indicator()"></i>
        <span class="noWrap" #text [matTooltipDisabled]="isEllipsisActive(text)" matTooltipPosition="left"
          matTooltipShowDelay="250" matTooltip="{{getHeader()}}">
          <span>{{getHeader()}}
          </span>
        </span>
        <span class="sub">&nbsp;{{getSubHeader()}}</span>
      </ng-container>
    </div>
  </div>
  <div class="body flex flex-row basis-full gap-3 pt-3">
    <div class="basis-1/3 left">
      <div class="toolbar flex basis-full items-center">
        <div class="basis-1/2">
          <h3>Tourplan</h3>
        </div>
        <div class="buttons flex basis-1/2 place-content-end">
          <i [className]="'icon icon-info'" (click)="$event.stopPropagation();"
            [matTooltip]="tour?.note? tour.note : 'Kein Tour-Hinweis hinterlegt'" [ngClass]="tour?.note? 'note' :'nonote'"></i>
          <i [className]="'icon icon-settings'" (click)="$event.stopPropagation();openSettings(tour)"
            [ngClass]="tour?.state !== 'FINISHED'? '' :'nonote'" *ngIf="rolesService?.hasRole('nexcore_tour_update')"
            [matTooltip]="'Tour-Einstellungen'"></i>
        </div>
      </div>
      <div class="columns flex flex-col basis-full main-component-wrapper items-start items-stretch"
        [ngClass]="{ loading : loading}">
        <div class="grey flex flex-row mt-5 mx-5">
          <div class="firstColumn flex basis-7 items-center place-content-center">
            <div class="letter">A</div>
          </div>
          <span class="flex basis-1/3 items-center row pl-2">Startort</span>
          <div
            class="flex basis-2/3 items-center place-content-end pr-3">
           {{tour?.departureAddress?.getAddressString()? tour?.departureAddress?.getAddressString() : '–'}}
            </div>
        </div>
        <div class="row grey flex flex-row mt-2 mb-2 mx-5">
          <span class="flex basis-1/2 items-center pl-2">Startzeit</span>
          <span class="flex basis-1/2 items-center place-content-end pr-3">
            <div>{{tour?.effectiveDepartureDateTime ? (tour?.effectiveDepartureDateTime | date:'dd.MM.yyyy HH:mm') :
              '–'}}</div>
          </span>
        </div>
        <ng-template ngFor let-isFirst="first" let-isLast="last" let-i="index" let-c="count" let-task
          [ngForOf]="tour?.tasks">
          <div *ngIf="isFirst && !objectChoosen && _route" class="distanceRow row mx-5 flex items-center">
            <div class="distanceLine pl-2" *ngIf="!objectChoosen && _route">{{_route?.sections[0]?.summary?.length / 1000 |
              number:'1.0-1'}} km / {{_route?.sections[0]?.summary?.baseDuration / 60 | number:'1.0-0'}} min</div>
          </div>
          <div class="taskRow mx-5 flex task border-b">
            <div class="firstColumn flex basis-7 items-center place-content-center">
              <div class="letter">{{toLetters(i + 2 )}}</div>
            </div>
            <div task-row class="flex flex-row basis-full items-center" [class.done]="task.state === 'done'" [class.last]="isLast"
              [task]=task>
              <div class="row grey flex flex-row mx-5"></div>
            </div>
          </div>

          <div class="distanceRow row mx-5 flex items-center" *ngIf="_route &&_route?.sections[i + 1]?.summary?.length !== 0  && !objectChoosen">
            <div class="distanceLine pl-2" *ngIf="_route &&_route?.sections[i + 1]?.summary?.length !== 0  && !objectChoosen">{{_route?.sections[i + 1]?.summary?.length / 1000 | number:'1.0-1'}} km /  {{_route?.sections[i + 1]?.summary?.baseDuration / 60  | number:'1.0-0'}} min</div>
          </div>
          <div class="grey flex flex-row mt-2 mx-5" *ngIf="isLast">
            <div class="firstColumn flex basis-7 items-center place-content-center">
              <div class="letter">{{toLetters(tour?.tasks?.length + 2 )}}</div>
            </div>
            <span class="flex basis-1/3 items-center row pl-2">Endort</span>
            <span class="flex basis-2/3 items-center place-content-end pr-3">
              {{tour?.arrivalAddress?.getAddressString()?
                tour?.arrivalAddress?.getAddressString(): '–'}}
            </span>
          </div>

          <div class="grey flex flex-row row mt-2 mx-5 items-center pr-3 pl-2" *ngIf="isLast">
            <span class="flex basis-1/2 items-center">Summe</span>
            <div class="flex basis-1/2 items-center place-content-end" *ngIf="(_sumDistance || _sumDuration)" >{{_sumDistance? (_sumDistance | number:'1.0-1') : '–'}} km /  {{ _sumDuration?  (_sumDuration | number:'1.0-0') : '–'}} Min</div>
            <div class="flex basis-1/2 items-center place-content-end" *ngIf="!(_sumDistance || _sumDuration)" >–</div>
          </div>
        </ng-template>
        <div *ngIf="!tour?.tasks || tour?.tasks?.length === 0">
          <div class="no-data">
            <p>keine Aufträge geplant</p>
          </div>
          <div class="grey flex flex-row mt-2 mx-5">
            <div class="firstColumn flex basis-7 items-center place-content-center">
              <div class="letter">{{toLetters(2 )}}</div>
            </div>
            <span class="flex basis-1/3 items-center row pl-2">Endort</span>
            <span class="flex basis-2/3 items-center place-content-end pr-3">
              {{tour?.arrivalAddress?.getAddressString()?
                tour?.arrivalAddress?.getAddressString(): '–'}}
            </span>
          </div>
          <div class="grey flex flex-row row mt-2 mx-5 items-center">
            <span class="flex basis-1/2 items-center pl-3">Summe</span>
            <div class="flex basis-1/2 items-center place-content-end pr-3" >–</div>
          </div>
        </div>
      </div>
    </div>
    <div class="basis-2/3">
      <div class="toolbar flex flex-row basis-full items-center">
        <h3>Route</h3>
      </div>
      <div class="columns flex flex-row basis-full main-component-wrapper items-start items-stretch px-5 pt-5"
        [ngClass]="{ loading : loading}">
        <app-tours-detail-planning-map class="flex basis-full map" [tour]="tour"
          (routeCalculated)=routeCalculated($event)>
        </app-tours-detail-planning-map>
      </div>
    </div>
  </div>
</div> -->
