 <div class="flex flex-row items-center" [ngClass]="className">
  <nx-default-button
    class="ml-2"
    (click)="dayBack()"
    [style]="'dark'"
    [tooltip]="'einen Tag zurück'"
    [icon]="'icon-arrow-left'">
  </nx-default-button>
  <p-calendar
    #calendar
    [(ngModel)]="selectedDate"
    [showIcon]="false"
    (onSelect)="dateChange($event)"
    [style]="{ width:'0px' }">
  </p-calendar>

  <nx-default-button
    class="ml-2"
    (click)="toggleCalendar($event)"
    [style]="'dark'"
    [tooltip]="'Tag'"
    [label]="selectedDate ? (selectedDate | date: 'dd.MM.yyyy') : 'Datum wählen'">
  </nx-default-button>
  <nx-default-button
    class="ml-2"
    (click)="!isToday() ? loadToday() : null"
    [style]="'dark'"
    [tooltip]="'aktueller Tag'"
    [disabled]="isToday()"
    [label]="'Heute'">
  </nx-default-button>
  <nx-default-button
    class="ml-2"
    [style]="'dark'"
    (click)="dayForward()"
    [tooltip]="'einen Tag vor'"
    [icon]="'icon-arrow-right'">
  </nx-default-button>
</div>
