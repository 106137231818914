<div>
  <nx-pageTitle-box
  [label]="contactTitle"
  [bcRootLvlLabel]="'Kunden'"
  [bcRootLvlPath]="'/customers'"
  [icon]="Contact?.getContactIcon(customer?.type)"
  [iconToolTip]="Contact?.getContactType(customer?.type)">
  </nx-pageTitle-box>
  <div class="bodyWrapper flex flex-row gap-5">
    <div class="basis-2/3 mt-[10px] widthFixForTable">
      <p-tabView [(activeIndex)]="activeIndex" [scrollable]="true">
        <p-tabPanel header="Aufträge">
          <div class="mt-[1.25rem]">
            <app-orders-list
              class="basis-full full"
              [customer]="customer?.id"
              *ngIf="customer?.id"
              [withMainHeadline]="false">
            </app-orders-list>
          </div>
        </p-tabPanel>
        <p-tabPanel
          header="Kontaktpersonen"
          *ngIf="rolesService?.hasRole('nexcore_contactPerson_view')">
          <div class="mt-[1.25rem]">
            <app-contact-person-list class="mt-2" [contact]="customer?.id"></app-contact-person-list>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
    <div class="basis-1/3 mt-[11px]">
      <div class="flex flex-col">
        <div class="basis-full">
          <div>
            <nx-headline-box class="mb-2" label="Allgemein" appearance="underline"
            class="mb-[1.25rem]">
            </nx-headline-box>
            <nx-headline-box label="Kundendaten">
            </nx-headline-box>
            <nx-default-box class="bg-white border-b border-gray" [label]="'Kontakt-ID'">
              {{customer?.number? customer.number: '-'}}
            </nx-default-box>
            <nx-default-box class="bg-white border-b border-gray" [label]="'Typ'">
              {{contactType? contactType: '-'}}
            </nx-default-box>
            <nx-default-box class="bg-white border-b border-gray" [label]="'Anrede'"
              *ngIf="customer?.type?.toString() === 'INDIVIDUAL'">
              {{customer?.salutation}}
            </nx-default-box>
            <nx-default-box class="bg-white border-b border-gray" [label]="'Vorname'"
              *ngIf="customer?.type?.toString() === 'INDIVIDUAL'">
              {{customer?.firstName}}
            </nx-default-box>
            <nx-default-box class="bg-white border-b border-gray" [label]="'Nachname'"
              *ngIf="customer?.type?.toString() === 'INDIVIDUAL'">
              {{customer?.lastName}}
            </nx-default-box>
            <nx-default-box class="bg-white border-b border-gray" [label]="'Firmenname'"
              *ngIf="customer?.type?.toString() === 'COMPANY'">
              {{customer?.companyName}}
            </nx-default-box>
            <nx-default-box class="bg-white border-b border-gray" [label]="'Firmenname Zusatz'"
              *ngIf="customer?.type?.toString() === 'COMPANY'">
              {{customer?.companyNameExtension}}
            </nx-default-box>
            <nx-default-box class="bg-white border-b border-gray" [label]="'Telefonnummer'">
              {{customer?.phone? customer.phone: '-'}}
            </nx-default-box>
            <nx-default-box class="bg-white border-b border-gray" [label]="'E-Mail'">
              {{customer?.email? customer.email: '-'}}
            </nx-default-box>
            <nx-default-box class="bg-white border-b border-gray" [label]="'Straße'">
              {{customer?.address?.street? customer?.address?.street: '-'}}
            </nx-default-box>
            <nx-default-box class="bg-white border-b border-gray" [label]="'Hausnummer'">
              {{customer?.address?.houseNumber? customer?.address?.houseNumber: '-'}}
            </nx-default-box>
            <nx-default-box class="bg-white border-b border-gray" [label]="'PLZ'">
              {{customer?.address?.postalCode? customer?.address?.postalCode: '-'}}
            </nx-default-box>
            <nx-default-box class="bg-white border-b border-gray" [label]="'Ort'">
              {{customer?.address?.city? customer?.address?.city: '-'}}
            </nx-default-box>
            <nx-default-box class="bg-white border-b border-gray" [label]="'Land'">
              {{customer?.address?.country? customer.address?.country: '-'}}
            </nx-default-box>
          </div>
          <div *ngFor="let section of customAttributeSections; let index = index; let isLast = last" class="mt-2">
            <nx-headline-box
              [label]="CheckSectionInAttributes(section?.id)? section?.label : 'Title'">
            </nx-headline-box>
            <nx-default-box
              *ngFor="let defintion of section?.customAttributeDefinitions; let isLast = last"
              class="bg-white border-b border-gray"
              [label]="defintion?.label">
              <ng-container
                *ngIf="defintion?.type !== 'DATETIME'">
                {{getValue(defintion?.id)}}
              </ng-container>
              <ng-container
                *ngIf="defintion?.type === 'DATETIME'">
                (getValue(defintion?.id) | date:'dd.MM.yyyy HH:mm')
              </ng-container>
            </nx-default-box>
          </div>
        </div>
      </div>
    </div>
</div>

