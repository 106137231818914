<!-- date-->
<div class="flex flex-row">
    <nx-default-box
        class="bg-white basis-full border-b border-gray"
        [label]="'Datum'">
        {{_protocol?.completedDateTime | date:'dd.MM.yyyy HH:mm'}}
    </nx-default-box>
</div> 

<!-- user -->
<div class="flex flex-row">
    <nx-default-box
        class="bg-white basis-full border-b border-gray"
        [label]="'Nutzer'"> 
        {{_protocol?.completedBy?.firstName}} {{_protocol?.completedBy?.lastName}}
    </nx-default-box>
</div>

<!-- assignment -->
<div class="flex flex-row">
    <nx-default-box
        class="bg-white basis-full border-b border-gray"
        [label]="'Zuweisung'">
        <app-task-assignment-strategy class="assignmnet" [assignment]="_protocol?.task?.assignment"/>
    </nx-default-box>
</div>

<!-- body -->
<ng-container *ngFor="let referenceSectionControlTemplate of referenceBodyControlTemplates; let i = index">
    <div
        *ngIf="referenceSectionControlTemplate?.settings?.show"
        class="sectionRow flex flex-row">
        <div
            class="basis-full bg-[#EBEEEF] border-b border-gray px-5 py-1 text-base font-medium">
            {{referenceSectionControlTemplate?.settings?.label}}
        </div>
    </div>
    <ng-container *ngFor="let referenceControlTemplate of referenceSectionControlTemplate?.controls; let j = index">
        <div class="flex flex-row"
            *ngIf="referenceControlTemplate?.settings?.show">
            <nx-default-box
                class="bg-white basis-full border-gray">

                <!-- label -->
                <ng-template nxTemplate="label">
                    <app-protocol-control-label-view 
                        class="text-base font-medium leading-3r" 
                        [controlTemplate]="referenceControlTemplate">
                    </app-protocol-control-label-view>
                </ng-template>

                <!-- value -->
                <app-protocol-control-value-view 
                    [controlTemplate]="referenceControlTemplate">
                </app-protocol-control-value-view>
            </nx-default-box>

        </div>
    </ng-container>
</ng-container>

<!-- completion -->
<ng-container *ngFor="let referenceSectionControlTemplate of referenceCompletionControlTemplates; let i = index">
    <div
        *ngIf="referenceSectionControlTemplate?.settings?.show" 
        class="sectionRow flex flex-row">
        <div
            class="basis-full bg-[#EBEEEF] border-b border-gray px-5 py-1 text-base font-medium">
            {{referenceSectionControlTemplate?.settings?.label}}
        </div>
    </div>
    <ng-container *ngFor="let referenceControlTemplate of referenceSectionControlTemplate?.controls; let j = index">
        <div class="flex flex-row"
            *ngIf="referenceControlTemplate?.settings?.show">
            <nx-default-box
                class="bg-white basis-full border-b border-gray last:border-b-0">

                <!-- label -->
                <ng-template nxTemplate="label">
                    <app-protocol-control-label-view 
                        class="text-base font-medium leading-3r"
                        [controlTemplate]="referenceControlTemplate">
                    </app-protocol-control-label-view>
                </ng-template>

                <!-- value -->
                <app-protocol-control-value-view 
                    [controlTemplate]="referenceControlTemplate">
                </app-protocol-control-value-view>
            </nx-default-box>
        </div>
    </ng-container>
</ng-container>