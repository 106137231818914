<div
 class="flex flex-row pt-[0.4rem] pb-1 pl-3 pr-3 bg-white rounded-md"
  [ngClass]="{ 'disabled': _location === undefined}">
  <div class="basis-2/3 flex flex-row gap-2">
    <nx-filter-multiSelect
      [(ngModel)] = "statesModel"
      [options]="statesOptions"
      [disabled]="_location === undefined"
      [className]="'table-filter'"
      [filter]="false"
      [showToggleAll]="false"
      [showHeader]="false"
      [appendTo]="'body'"
      [showClear]="true"
      (onChange)="onStateModelChange()"
      (onClear)="onStateModelChange()"
      placeholder="Status"
      optionLabel="label">
      <ng-template let-option pTemplate="item">
        <div class="inline-block vertical-align-middle">
          <span class="ml-1 mt-1">{{ option.name }}</span>
        </div>
      </ng-template>
  </nx-filter-multiSelect>
  </div>
  <div class="basis-1/3 flex flex-row justify-end">
    <p-dropdown
      [disabled]="_location === undefined"
      [(ngModel)] = "sortModel"
      [options]="sortOptions"
      (onChange)="onSortModelChange()"
      placeholder="Sortierung"
      [styleClass]="'sort'"
      appendTo="body"
      optionLabel="label"
      [showClear]="false">
    </p-dropdown>
  </div>
</div>

<div
  #xScrollContainer
  class="flex flex-row gap-4 no-scrollbar mt-2 h-[calc(100%-56px)] overflow-y-hidden overflow-x-auto">
  
  <nx-empty-box
    class="w-full"
    *ngIf="_location === undefined"
    [label]="'Keine Niederlassung ausgewählt.'">
  </nx-empty-box>

  <div
    *ngFor="let day of tourDays"
    class="flex-shrink-0 min-w-[500px] max-w-[570px]"
    [ngClass]="{
      'min-w-[500px]' : !isReduced(day),
      'max-w-[570px]' : !isReduced(day),
      'expanded' : !isReduced(day),
      'w-[44px]' : isReduced(day),
      'overflow-hidden' : !isReduced(day),
    }">
    <div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
      <div class="basis-1/3 flex flex-row items-center font-medium"
        [ngClass]="{ 'ml-3' : !isReduced(day)}">
        <ng-container
          *ngIf="!isReduced(day)">
          {{day?.getDate() | date:'EEE, dd.MM.yyyy'}}
        </ng-container>
        <nx-default-button
          class="ml-2"
          *ngIf="isReduced(day)"
          (click)="expand(day)"
          [tooltip]="'Tag erweitern'"
          [style]="'light'"
          [icon]="'icon-after'">
        </nx-default-button>
      </div>
      <div class="basis-2/3 flex flex-row justify-end items-center mr-3"
        *ngIf="!isReduced(day)">
        <nx-default-button
          class="ml-2"
          (click)="createTour(day)"
          [disabled]="_location === undefined"
          *ngIf="rolesService?.hasRole('nexcore_tour_create') && !isReduced(day)"
          [tooltip]="'neue Tour anlegen'"
          [label]="'Tour anlegen'"
          [style]="'light'"
          [icon]="'icon-plus'">
        </nx-default-button>
        <nx-default-button
          class="ml-2"
          *ngIf="!isReduced(day)"
          (click)="reduce(day)"
          [tooltip]="'Tag reduzieren'"
          [style]="'light'"
          [icon]="'icon-back-to'">
        </nx-default-button>
      </div>
  </div>

    <div #yScrollContainer class="overflow-auto no-scrollbar h-[calc(100%-48px)]">

      <ng-container
        *ngIf="!isReduced(day)">
      
        <ng-container
          *ngIf="_location !== undefined && day?.getTourDayEntities()?.length > 0">
          <div
            *ngFor="let entities of day.getTourDayEntities()"
            class="tourGroup unplanned  ">
            <h2 class="text-base font-medium p-2">{{entities?.getName()}}</h2>
            <div tours-planner-tours-list
              class="toursList"
              [sortBy]="sortModel?.id"
              [tours]="entities?.getTours()"
              (refetch)="refetchQuery()">
            </div>
          </div>
        </ng-container>

        <nx-empty-box
          *ngIf="_location !== undefined && day?.getTourDayEntities()?.length === 0"
          [label]="'Keine Touren gefunden.'">
        </nx-empty-box>

      </ng-container>

      <ng-container
        *ngIf="isReduced(day)">
        <div class="h-full flex justify-center">
          <div class="border-l border-dashed border-[#c3c3c3]"></div>
        </div>
      </ng-container>

    </div>

  </div>
</div>