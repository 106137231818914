<!-- date-->
<div class="flex flex-row">
    <nx-default-box
        *ngIf="!showEmptyBox(_leftProtocol); else noProtocolBox"
        class="bg-white basis-1/2 border-b border-gray border-r"
        [label]="'Datum'">
        {{_leftProtocol?.completedDateTime | date:'dd.MM.yyyy HH:mm'}}
    </nx-default-box>
    <nx-default-box 
        *ngIf="!showEmptyBox(_rightProtocol); else noProtocolBox"
        class="bg-white basis-1/2 border-b border-gray"
        [label]="'Datum'">
        {{_rightProtocol?.completedDateTime | date:'dd.MM.yyyy HH:mm'}}
    </nx-default-box>
</div> 

<!-- user -->
<div class="flex flex-row">
    <nx-default-box
        *ngIf="!showEmptyBox(_leftProtocol); else emptyBox"
        class="bg-white basis-1/2 border-b border-gray border-r"
        [label]="'Nutzer'"> 
        {{_leftProtocol?.completedBy?.firstName}} {{_leftProtocol?.completedBy?.lastName}}
    </nx-default-box>
    <nx-default-box
        *ngIf="!showEmptyBox(_rightProtocol); else emptyBox"
        class="bg-white basis-1/2 border-b border-gray" 
        [label]="'Nutzer'">
        {{_rightProtocol?.completedBy?.firstName}} {{_rightProtocol?.completedBy?.lastName}}
    </nx-default-box>
</div>

<!-- assignment -->
<div class="flex flex-row">
    <nx-default-box
        *ngIf="!showEmptyBox(_leftProtocol); else emptyBox"
        class="bg-white basis-1/2 border-b border-gray border-r"
        [label]="'Zuweisung'">
        <app-task-assignment-strategy class="assignmnet" [assignment]="_leftProtocol?.task?.assignment"/>
    </nx-default-box>
    <nx-default-box 
        *ngIf="!showEmptyBox(_rightProtocol); else emptyBox"
        class="bg-white basis-1/2 border-b border-gray"
        [label]="'Zuweisung'">
        <app-task-assignment-strategy class="assignmnet" [assignment]="_rightProtocol?.task?.assignment"/>
    </nx-default-box>
</div>

<!-- body -->
<ng-container *ngFor="let referenceSectionControlTemplate of referenceBodyControlTemplates; let i = index">
    <div
        *ngIf="shouldShowRow(leftBodyControlTemplates?.[i], rightBodyControlTemplates?.[i])"
        class="sectionRow flex flex-row">
        <div
            *ngIf="!showEmptyBox(_leftProtocol); else emptyHeadline"
            class="basis-1/2 bg-[#EBEEEF] border-b border-gray px-5 py-1 text-base font-medium">
            {{referenceSectionControlTemplate?.settings?.label}}
        </div>
        <div
            *ngIf="!showEmptyBox(_rightProtocol); else emptyHeadline"
            class="basis-1/2 bg-[#EBEEEF] border-b border-gray px-5 py-1 text-base font-medium">
            {{referenceSectionControlTemplate?.settings?.label}}
        </div>
    </div>
    <ng-container *ngFor="let referenceControlTemplate of referenceSectionControlTemplate?.controls; let j = index">
        <div class="flex flex-row"
            *ngIf="shouldShowRow(leftBodyControlTemplates?.[i]?.controls?.[j], rightBodyControlTemplates?.[i]?.controls?.[j])">
            <nx-default-box
                *ngIf="!showEmptyBox(_leftProtocol); else emptyBox"
                class="bg-white basis-1/2 border-b border-gray border-r">

                <!-- label -->
                <ng-template nxTemplate="label">
                    <app-protocol-control-label-view class="text-base font-medium leading-3r" [controlTemplate]="referenceControlTemplate"></app-protocol-control-label-view>
                </ng-template>

                <!-- value -->
                <app-protocol-control-value-view [controlTemplate]="leftBodyControlTemplates?.[i]?.controls?.[j]"></app-protocol-control-value-view>
            </nx-default-box>
            <nx-default-box
                *ngIf="!showEmptyBox(_rightProtocol); else emptyBox"
                class="bg-white basis-1/2 border-b border-gray">

                <!-- label -->
                <ng-template nxTemplate="label">
                    <app-protocol-control-label-view class="text-base font-medium leading-3r" [controlTemplate]="referenceControlTemplate"></app-protocol-control-label-view>
                </ng-template>

                <!-- value -->
                <app-protocol-control-value-view [controlTemplate]="rightBodyControlTemplates?.[i]?.controls?.[j]"></app-protocol-control-value-view>
            </nx-default-box>
        </div>
    </ng-container>
</ng-container>

<!-- completion -->
<ng-container *ngFor="let referenceSectionControlTemplate of referenceCompletionControlTemplates; let i = index">
    <div
        *ngIf="shouldShowRow(leftCompletionControlTemplates?.[i], rightCompletionControlTemplates?.[i])" 
        class="sectionRow flex flex-row">
        <div
            *ngIf="!showEmptyBox(_leftProtocol); else emptyHeadline"
            class="basis-1/2 bg-[#EBEEEF] border-b border-gray px-5 py-1 text-base font-medium">
            {{referenceSectionControlTemplate?.settings?.label}}
        </div>
        <div *ngIf="!showEmptyBox(_rightProtocol); else emptyHeadline" 
            class="basis-1/2 bg-[#EBEEEF] border-b border-gray px-5 py-1 text-base font-medium">
            {{referenceSectionControlTemplate?.settings?.label}}
        </div>
    </div>
    <ng-container *ngFor="let referenceControlTemplate of referenceSectionControlTemplate?.controls; let j = index; let isLast = last">
        <div class="flex flex-row"
            *ngIf="shouldShowRow(leftCompletionControlTemplates?.[i]?.controls?.[j], rightCompletionControlTemplates?.[i]?.controls?.[j])">
            <nx-default-box
                *ngIf="!showEmptyBox(_leftProtocol); else emptyBox"
                [ngClass]="{'border-b-0': isLast}"
                class="bg-white basis-1/2 border-b border-gray border-r">

                <!-- label -->
                <ng-template nxTemplate="label">
                    <app-protocol-control-label-view class="text-base font-medium leading-3r" [controlTemplate]="referenceControlTemplate"></app-protocol-control-label-view>
                </ng-template>

                <!-- value -->
                <app-protocol-control-value-view [controlTemplate]="leftCompletionControlTemplates?.[i]?.controls?.[j]"></app-protocol-control-value-view>
            </nx-default-box>
            <nx-default-box
                *ngIf="!showEmptyBox(_rightProtocol); else emptyBox"
                [ngClass]="{'border-b-0': isLast}"
                class="bg-white basis-1/2 border-b border-gray">

                <!-- label -->
                <ng-template nxTemplate="label">
                    <app-protocol-control-label-view class="text-base font-medium leading-3r" [controlTemplate]="referenceControlTemplate"></app-protocol-control-label-view>
                </ng-template>

                <!-- value -->
                <app-protocol-control-value-view [controlTemplate]="rightCompletionControlTemplates?.[i]?.controls?.[j]"></app-protocol-control-value-view>
            </nx-default-box>
        </div>
    </ng-container>
</ng-container>

<!-- shows up, when there´s no protocol and indicates this -->
<ng-template #noProtocolBox>
    <div class="bg-white border-r text-[#848488] basis-1/2 flex items-center justify-center text-base font-light ">Kein Protokoll vorhanden.</div>
</ng-template>

<!-- empty spacer as box substitute if there´s no protocol -->
<ng-template #emptyBox>
    <div class="bg-white basis-1/2 border-r"></div>
</ng-template>

<!-- empty spacer as box substitute if there´s no protocol -->
<ng-template #emptyHeadline>
    <div class="bg-white basis-1/2 border-r"></div>
</ng-template>