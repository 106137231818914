<div class="flex flex-row basis-full tasks" [class.completed]="task?.state === 'COMPLETED'" *ngIf="task?.id">
  
  <!-- start -->
  <div class="flex flex-col basis-12">
    <div class="flex flex-row h-full">
      <div [ngStyle]="{ 'background-color': task?.getBackgroundColor() }" class="w-[5px] h-full"></div>
      <div class="pl-1 pt-1"><i [className]="task?.getTypeIcon()" [matTooltip]="task?.getType()"></i></div>
    </div>
  </div>

  <!-- middle/end -->
  <div class="flex justify-items-start flex-col basis-full px-2 py-1">
    <div class="flex justify-items-start flex-row">
      <div class="flex justify-items-start w-full flex-col mb-[0.125rem]">
        <div class="mb-1">
          <ng-container *ngFor="let column of columns; let i = index; let isFirst = first; let isLast = last">
            <ng-container [ngTemplateOutlet]="getTemplateForColumn(column)" [ngTemplateOutletContext]="{ $implicit: task }"></ng-container>
            <ng-container *ngIf="shoulShowSpacer(column, columns, i, isLast)"> / </ng-container>
          </ng-container>
        </div>
        
        <div class="flex flex-row row">
          <span class="text-[#848488]">
          {{task?.description? task?.description: '-'}}
          </span>
        </div>
      </div>
      <div class="orderInfo flex justify-end flex-row pt-1" *ngIf="showIconMenu">
        <!-- files START -->
        <ng-template #filesOverlayContent>
          <div class="overlay-box">
            <div class="overlay-arrow"></div>
            <app-order-files [order]="task.order?.id" [style]="'overlay'"/>
          </div>
        </ng-template>
        <!--  -->
        <nx-default-button
          (click)="openOverlay(filesOverlayContent, filesTriggerButton)"
          #filesTriggerButton
          [tooltip]="'Anhänge anzeigen'"
          [style]="'light'"
          [badgeValue]="task?.order?.fileCount"
          class="mr-2"
          [icon]="'icon-file-attachment'">
        </nx-default-button>
        <!-- files END -->
        <!-- comments START -->
        <ng-template #commentsOverlayContent>
          <div class="overlay-box">
            <div class="overlay-arrow"></div>
            <div>
              <nx-headline-box
                [label]="'Auftrag'"></nx-headline-box>
              <nx-default-box
                class="mb-2"
                [label]="'Hinweis'">{{task?.order?.note}}</nx-default-box>
              <app-order-comments-list [order]="task?.order?.id" [style]="'overlay'" />
            </div>
          </div>
        </ng-template>
        <!--  -->
        <nx-default-button
          #commentsTriggerButton
          (click)="openOverlay(commentsOverlayContent, commentsTriggerButton)"
          [tooltip]="'Hinweis/Kommentare anzeigen'"
          [style]="'light'"
          class="mr-2"
          [badgeValue]="task.calculateCommentsAndNoteBadgeCount()"
          [icon]="'icon-annotation'">
        </nx-default-button>
        <!-- comments END -->
        <nx-default-button
          *ngIf="showCommandMenu"
          [style]="'light'"
          [data]="task"
          [commandMenuItems]="commandMenuItems"
          (onCommand)="executeCommand($event)"
          [icon]="'icon-options'">
        </nx-default-button>
      </div>
    </div>    
    <!-- end -->
  </div>

</div>

<ng-template #dueDateTimePeriod let-task>
  <ng-container *ngIf="task?.dueDateTimePeriod; else noContent">
    <span class="bg-[#EBEEEF] text-black whitespace-nowrap leading-[1.25rem] px-2 py-1 rounded-md" [ngClass]="task?.state">
      {{tasksService.getDeliveryTime(task?.dueDateTimePeriod)}}
    </span>
    <span class="dueDate pl-1">{{tasksService.getDeliveryDateFull(task?.dueDateTimePeriod)}}</span>
  </ng-container>
</ng-template>

<ng-template #completedDateTime let-task>
  <ng-container *ngIf="task?.completedDateTime; else noContent">
    <span class="bg-[#EBEEEF] text-black whitespace-nowrap leading-[1.25rem] px-2 py-1 rounded-md" [ngClass]="task?.state">
      {{task?.completedDateTime | date: 'HH:mm'}}
    </span>
    <span class="dueDate pl-1">{{task?.completedDateTime | date: 'dd.MM.yyyy'}}</span>
  </ng-container>
</ng-template>

<ng-template #orderCustomerName let-task>
  <ng-container *ngIf="task?.order?.customer; else noContent">
    {{getContactName(task?.order?.customer)}}
  </ng-container>
</ng-template>

<ng-template #orderNumber let-task>
  <ng-container *ngIf="task?.order?.number; else noContent">
    {{task?.order?.number}}
    <i [className]="'icon icon-jump-to'" [newTabNavigation]="['./orders/order', task?.order?.id]"></i>
  </ng-container>
</ng-template>

<ng-template #state let-task>
  <ng-container *ngIf="task?.state; else noContent">
    <p-tag [value]="task?.getStateLabel()" severity="task?.getStateSeverity()" class="ml-1"></p-tag>
  </ng-container>
</ng-template>

<ng-template #addressStreet let-task>
  <ng-container *ngIf="(task.address?.street || task.address?.houseNumber); else noContent">
    {{task.address?.street}}&nbsp;{{task.address?.houseNumber}}
  </ng-container>
</ng-template>

<ng-template #addressPostalCode let-task>
  <ng-container *ngIf="task.address?.postalCode; else noContent">
    {{task.address?.postalCode}}
  </ng-container>
</ng-template>

<ng-template #addressCity let-task>
  <ng-container *ngIf="task.address?.city; else noContent">
    {{task.address?.city}}
  </ng-container>
</ng-template>

<ng-template #addessGeocodingGrade let-task>
  <app-address-indicator
    label
    class="inline-flex mt-[0.25rem] ml-2"
    size="small"
    [grade]="task?.address?.geocodingResultGrade?.toString()">
  </app-address-indicator>
</ng-template>

<ng-template #addressSummary let-task>
  <div class="inline-flex flex-row">
    <div>{{task?.address?.getAddressString() ? task?.address?.getAddressString() : '-'}}</div>
    <div>
      <app-address-indicator
        label
        class="mt-[0.25rem] ml-2"
        size="small"
        [grade]="task?.address?.geocodingResultGrade?.toString()">
      </app-address-indicator>
    </div>
  </div>
</ng-template>

<ng-template #addressNote let-task>
  <ng-container *ngIf="task?.address?.note; else noContent">
    {{task?.address?.note}}
  </ng-container>
</ng-template>

<ng-template #orderLocation let-task>
  <ng-container *ngIf="task?.order?.location; else noContent">
    {{task?.order?.location?.label ? task?.order?.location?.label : task?.order?.location?.name}}
  </ng-container>
</ng-template>

<ng-template #noContent>
  -
</ng-template>