<div>
  <nx-pageTitle-box
  [label]="resource?.number + '/ ' + resource?.name"
  [bcRootLvlLabel]="'Ressourcen'"
  [bcRootLvlPath]="'/resources'"
  [icon]="'icon-item icon'"
  [iconToolTip]="">
    <ng-template nxTemplate="end">
      <nx-default-button *ngIf="this.rolesService.hasRole('nexcore_resource_update')"
        (click)="editResource()"
        [tooltip]="'Ressource bearbeiten'"
        [icon]="'icon-edit'">
      </nx-default-button>
    </ng-template>
  </nx-pageTitle-box>
  <div class="bodyWrapper flex flex-row gap-5">
    <div class="basis-2/3 mt-[10px] widthFixForTable">
      <p-tabView [(activeIndex)]="activeIndex" [scrollable]="true">
        <p-tabPanel header="Aufträge">
          <div class="mt-[1.25rem]">
            <app-orders-list
              [withMainHeadline]="false"
              [resource]="resource?.id"
              *ngIf="resource?.id">
            </app-orders-list>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
      <div class="basis-1/3 mt-[11px]">
        <div class="flex flex-col">
          <div class="basis-full">
            <div>
              <nx-headline-box class="mb-2" label="Allgemein" appearance="underline"
              class="mb-[1.25rem]">
              </nx-headline-box>
              <nx-headline-box label="Ressourcendaten">
              </nx-headline-box>
              <nx-default-box class="bg-white border-b border-gray" [label]="'Nummer'">
                {{resource?.number? resource.number: '-'}}
              </nx-default-box>
              <nx-default-box class="bg-white border-b border-gray" [label]="'Name'">
                {{resource?.name? resource?.name: '-'}}
              </nx-default-box>
              <nx-default-box class="bg-white border-b border-gray" [label]="'Funktion'">
                {{functionName? functionName: '-'}}
              </nx-default-box>
              <nx-default-box class="bg-white border-b border-gray" [label]="'Niederlassung'">
                {{resource?.location? resource?.location?.name : '-'}}
              </nx-default-box>
            </div>
            <div *ngFor="let section of customAttributeSections; let index = index; let isLast = last" class="mt-2">
              <nx-headline-box
                [label]="section?.label">
              </nx-headline-box>
              <nx-default-box
                *ngFor="let defintion of section?.customAttributeDefinitions; let isLast = last"
                class="bg-white border-b border-gray"
                [label]="defintion?.label">
                <ng-container
                  *ngIf="defintion?.type !== 'DATETIME'">
                  {{getValue(defintion?.id)}}
                </ng-container>
                <ng-container
                  *ngIf="defintion?.type === 'DATETIME'">
                  (getValue(defintion?.id) | date:'dd.MM.yyyy HH:mm')
                </ng-container>
              </nx-default-box>
            </div>
          </div>
        </div>
      </div>
  </div>
  </div>

