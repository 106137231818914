<nx-headline-box class="mb-[1.25rem]"
  appearance="underline"
  label="Details">
</nx-headline-box>

<nx-headline-box
  label="Auftragsdaten">
</nx-headline-box> 
<div class="mb-2">
  <div class="flex flex-col">
    <nx-default-box class="bg-white border-b border-gray"
      [label]="'Auftragsnummer'">
      {{order?.number}}
    </nx-default-box>
    <nx-default-box class="bg-white border-b border-gray"
      [label]="'Typ'">
      {{Order.getOrderType(order?.type?.toString())}}
    </nx-default-box>
    <nx-default-box class="bg-white border-b border-gray"
      [label]="'Niederlassung'">
      {{order?.location?.name}}
    </nx-default-box>
    <nx-default-box class="bg-white border-b border-gray"
      [label]="'Archivieren'">
      {{order?.archive ? 'ja' : 'nein'}}
    </nx-default-box>
    <nx-default-box class="bg-white border-b border-gray"
    [label]="'Kunde (Nr.)'">
    <div class="flex items-center">
      <a [routerLink]="['/customers/customer/', order?.customer?.id ]" class="jump">
        {{order?.customer?.getFullName()? order?.customer?.getFullName() :'-'}} {{order?.customer?.number? '(' +
        order?.customer.number+ ')': ''}}
        <i [className]="'icon icon-jump-to'"></i>
      </a>
    </div>
  </nx-default-box>
    <nx-default-box class="bg-white border-b border-gray"
      [label]="'Bestellnummer'">
      {{order?.customerOrderNumber}}
    </nx-default-box>
    
    <nx-default-box class="bg-white border-b border-gray"
      [label]="'Besteller'">
      {{order?.orderer?.getFullName()}}
    </nx-default-box>

    <nx-default-box class="bg-white border-b border-gray"
      [label]="'Hinweis'">
      {{order?.note}}
    </nx-default-box>
  </div>
</div>

<div *ngFor="let section of customSections; let index = index; let isLast = last" class="mb-2">
  
  <nx-headline-box
    [label]="section?.label">
  </nx-headline-box> 

  <nx-default-box
    *ngFor="let defintion of section?.customAttributeDefinitions; let isLast = last"
    class="bg-white border-b border-gray"
    [label]="defintion?.label">
    <ng-container
      *ngIf="defintion?.type !== 'DATETIME'">
      {{getValue(defintion?.id)}}
    </ng-container>
    <ng-container
      *ngIf="defintion?.type === 'DATETIME'">
      (getValue(defintion?.id) | date:'dd.MM.yyyy HH:mm')
    </ng-container>
  </nx-default-box>

</div>